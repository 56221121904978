import { tap } from 'rxjs/operators';
import { JsonFormService } from './json-form.service';
import { Select2Service } from '../select2/select2.service';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, first, Observable, Subscription } from 'rxjs';
import { JsonFormControls, JsonFormData, JsonFormRow, JsonFormSeparator } from './json-form.model';
import { ConfirmPasswordValidator } from 'src/app/modules/auth/components/registration/confirm-password.validator';
import {TranslateService} from "@ngx-translate/core";
import {getLocaleFirstDayOfWeek} from "@angular/common";

@Component({
  selector: 'app-json-form',
  templateUrl: './json-form.component.html',
  styleUrls: ['./json-form.component.scss'],
})
export class JsonFormComponent implements OnChanges, OnDestroy {
  @Input() jsonFormData: JsonFormData;
  @Input() serverErrors: any;
  @Input() submitLoading: boolean = false;
  @Output() submitEvent = new EventEmitter<FormGroup>();
  @Output() resetEvent = new EventEmitter<FormGroup>();
  public myForm: FormGroup = this.fb.group({});
  private unsubscribe: Subscription[] = [];


  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private select2Service: Select2Service,
    private jsonFormService: JsonFormService,
    public translate: TranslateService

  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (!changes.jsonFormData?.firstChange) {
      if (changes.jsonFormData) {
        this.createForm(this.jsonFormData);
      }
    }
  }

  createForm(json: JsonFormData) {
    for (const row of json.layout) {
      if (row.visibleIf) {
        if (this.myForm.get(row.visibleIf?.target)?.value) {
          const targetValue = this.myForm.get(row.visibleIf?.target)?.value;
          const visible = this.jsonFormService.where(
            targetValue,
            row.visibleIf.operator,
            row.visibleIf.value
          );
          if (!visible) {
            row.visible = true;
            row.controls.forEach(control => {
              if (control.visible) {
                this.myForm.addControl(
                  control.name,
                  this.fb.control({ value: control.value, disabled: control.disabled })
                );
              }
              this.visibleIf(control);
              this.disabledIf(control);
              this.onChange(control);
            })
          } else {
            row.visible = false;
            row.controls.forEach(control => {
              this.myForm.removeControl(control.name);
            })
          }
        }
        this.myForm.get(row.visibleIf.target)?.valueChanges.subscribe(targetValue => {
          console.log(targetValue)
          const rowVisible = this.jsonFormService.where(
            targetValue,
            row.visibleIf.operator,
            row.visibleIf.value
          );
          if (!rowVisible) {
            row.visible = true;
            row.controls.forEach(control => {
              if (control.visible) {
                this.myForm.addControl(
                  control.name,
                  this.fb.control({ value: control.value, disabled: control.disabled })
                );
              }

              this.visibleIf(control);
              this.disabledIf(control);
              this.onChange(control);
            })
          } else {
            row.visible = false;
            row.controls.forEach(control => {
              console.log(control)
              this.myForm.removeControl(control.name);
              // this.cdRef.markForCheck();
            })
            console.log(this.myForm.controls)

          }
        })
      } else {
        for (const control of row.controls) {
          const validatorsToAdd = [];
          for (const validator of control.validators) {

            for (const [key, value] of Object.entries(validator)) {
              switch (key) {
                case 'min':
                  validatorsToAdd.push(Validators.min(value));
                  break;
                case 'max':
                  validatorsToAdd.push(Validators.max(value));
                  break;
                case 'required':
                  if (value) {
                    validatorsToAdd.push(Validators.required);
                  }
                  break;
                case 'requiredTrue':
                  if (value) {
                    validatorsToAdd.push(Validators.requiredTrue);
                  }
                  break;
                case 'email':
                  if (value) {
                    validatorsToAdd.push(Validators.email);
                  }
                  break;
                case 'minLength':
                  validatorsToAdd.push(Validators.minLength(value));
                  break;
                case 'maxLength':
                  validatorsToAdd.push(Validators.maxLength(value));
                  break;
                case 'pattern':
                  validatorsToAdd.push(Validators.pattern(value));
                  break;
                case 'nullValidator':
                  if (value) {
                    validatorsToAdd.push(Validators.nullValidator);
                  }
                  break;
                case 'ConfirmPassword':
                  if (value) {
                    validatorsToAdd.push(this.jsonFormService.ConfirmPassword(this.myForm));
                  }
                  break;
                default:
                  break;
              }
            }
          }
          if (control.visible) {
            this.myForm.addControl(
              control.name,
              this.fb.control({ value: control.value, disabled: control.disabled }, validatorsToAdd)
            );
          }
          this.visibleIf(control);
          this.disabledIf(control);
          this.onChange(control);
        }
      }
    }
  }

  visibleIf(control: JsonFormControls) {
    if (control.visibleIf?.target) {//controllo sui vari input - select etc..
      if (this.myForm.get(control.visibleIf?.target)?.value) {
        const targetValue = this.myForm.get(control.visibleIf?.target)?.value;
        const visible = this.jsonFormService.where(
          targetValue,
          control.visibleIf.operator,
          control.visibleIf.value
        );
        if (visible) {
          setTimeout(() => {
            control.visible = true;
            this.addControls(control)
            this.onChange(control)
            this.disabledIf(control)
          })
        } else {
          control.visible = false;
          if (this.myForm && this.myForm.get(control.name)) {
            this.myForm.get(control.name)?.patchValue(null);
            this.myForm.removeControl(control.name);
          }
        }
      }
      this.myForm.get(control.visibleIf?.target)?.valueChanges.subscribe(targetValue => {
        const visible = this.jsonFormService.where(
          targetValue,
          control.visibleIf.operator,
          control.visibleIf.value
        );
        if (visible) {
          setTimeout(() => {
            control.visible = true;
            this.addControls(control)
            this.onChange(control)
            this.disabledIf(control)
            this.visibleIf(control)
            this.cdRef.markForCheck()
          })
        } else {
          control.visible = false;
          if (this.myForm && this.myForm.get(control.name)) {
            this.myForm.get(control.name)?.patchValue(null);
            this.myForm.removeControl(control.name);
            this.cdRef.markForCheck()
          }
        }
      })
    }
  }

  disabledIf(control: JsonFormControls) {
    if (control.disabledIf?.target) {
      if (this.myForm.get(control.disabledIf?.target)?.value) {
        const targetValue = this.myForm.get(control.disabledIf?.target)?.value;
        const disabled = this.jsonFormService.where(
          targetValue,
          control.disabledIf.operator,
          control.disabledIf.value
        );
        if (!disabled) {
          this.myForm.get(control.name)?.disable()
          this.myForm.get(control.name)?.reset();
          if (control.type === 'select' || control.type === 'select-search') {
            control?.reset()
          }
          control.disabled = true;
        } else {
          this.myForm.get(control.name)?.enable()
          control.disabled = false;
        }
      }
      this.myForm.get(control.disabledIf?.target)?.valueChanges.subscribe(targetValue => {
        const disabled = this.jsonFormService.where(
          targetValue,
          control.disabledIf.operator,
          control.disabledIf.value
        );
        if (!disabled) {
          this.myForm.get(control.name)?.disable()
          this.myForm.get(control.name)?.reset();
          if (control.type === 'select' || control.type === 'select-search') {
            control?.reset()
          }
          control.disabled = true;
        } else {
          this.myForm.get(control.name)?.enable()
          control.disabled = false;
        }
      })
    }
  }

  onChange(control: JsonFormControls) {
    console.log(control)
    this.myForm.get(control.unlockedBy)?.valueChanges.subscribe(value => {
      control.value = '';
      if (value) {
        setTimeout(() => {
          // se esiste ifunlock e il campo e' abilitato
          if (control.ifUnlock && !control.disabled) {
            control.ifUnlock(value, 'onChange')
          } else {
            // altrimenti lo abilito
            control.disabled = false;
            this.myForm.get(control.name)?.enable();
          }
        })
      } else {
        this.myForm.get(control.name)?.reset();
        if (control.type === 'select' || control.type === 'select-search') {
          control.reset()
        }
      }
      this.cdRef.markForCheck()
    })
  }

  separatorIsVisible(row: JsonFormSeparator) {
    if (row.separator?.visibleIf?.target) {
      const targetValue = this.myForm.get(row.separator.visibleIf?.target)?.value;
      const visible = this.jsonFormService.where(
        targetValue,
        row.separator.visibleIf.operator,
        row.separator.visibleIf.value
      );
      return visible
    } else {
      return true
    }
  }

  addControls(control: JsonFormControls) {
    this.jsonFormService.setValidators(control, this.myForm).then((validators) => {
      if (control.visible) {
        this.myForm.addControl(
          control.name,
          this.fb.control({ value: control.value, disabled: control.disabled }, validators)
        );
      }
    })

  }

  onSubmit() {
    this.submitEvent.emit(this.myForm)
  }

  resetForm() {
    this.serverErrors = '';
    this.createForm(this.jsonFormData);
    this.myForm.reset();
    this.resetEvent.emit();
    this.select2Service.resetAllFields();
    this.cdRef.detectChanges();
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
