import {Injectable} from '@angular/core';
import {map, Observable, throwError} from "rxjs";
import {PracticeModel} from "../models/pratiche/practice.model";
import {routes} from "../../routes";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private translate: TranslateService
  ) {
  }

  initAppSettings() {
    switch (location.hostname) {
      case 'sml.test.mandorlab.it':
        environment.apiUrl = 'https://api.sml.test.mandorlab.it/api';
        this.setItalian()
        break;
      case 'soluzionemedicolegale.it':
      case 'www.soluzionemedicolegale.it':
        environment.apiUrl = 'https://api.soluzionemedicolegale.it/api';
        this.setItalian()
        break;
      case 'sml.test.es.mandorlab.it':
        environment.apiUrl = 'https://api.sml.test.es.mandorlab.it/api';
        this.setEspanol()
        break;
      case 'app.solucionmedicolegal.es':
        environment.apiUrl = 'https://api.solucionmedicolegal.es/api';
        this.setEspanol()
        break;
      default:
        environment.apiUrl = 'https://api.sml.test.mandorlab.it/api';
        this.setItalian();
        break;
    }
  }

  private setItalian() {
    this.translate.use('it')
    console.log('it')
  }

  private setEspanol() {
    this.translate.use('es')
    console.log('es')
  }

}
