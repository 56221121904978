<div [formGroup]="parentForm">
  <div class="fv-row" [ngClass]="{'mt-8': !description}">
    <div class="d-flex flex-stack">
      <div class="fw-bold me-8">
        <label class="fs-6" [ngClass]="{'mb-2': description, 'opacity-50': isDisabled}" >{{ label }}</label>
        <div class="fs-7 text-gray-400" [innerHTML]="description" [ngClass]="{'opacity-75': isDisabled}"></div>
      </div>
      <div class="d-flex align-items-center"
           [ngClass]="{'opacity-75': isDisabled, 'mt-4': description}">
        <label class="form-check form-check-custom form-check-solid border rounded border-2">
          <input class="form-check-input h-30px w-30px" type="checkbox" [formControlName]="formControlName"
                 (change)="onCheckboxChange($event)"/>
        </label>
      </div>
    </div>
  </div>
</div>
