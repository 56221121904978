import {Select2Service} from './select2.service';
import {FormGroup} from '@angular/forms';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

declare var $: any

@Component({
  selector: 'app-select2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Select2Component implements OnInit, OnChanges, OnDestroy {

  @Input() placeholder: string;
  @Input() validators: any;
  @Input() type: string;
  @Input() disabled?: boolean | undefined = false;
  @Input() parentForm: FormGroup;
  @Input() formControlName: string;
  @Input() emptyValue: boolean = true;
  @Input() allowClear: boolean = true;
  @Input() options: [{ label: string, value: string }] | any;
  @Input() defaultValue?: any;
  @ViewChild('select2', {read: ElementRef, static: true}) select2: ElementRef<any>;

  multipleSelectedValue: any[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private select2Service: Select2Service,
    public translate: TranslateService
  ) {
    this.select2Service.$reset.subscribe(() => {
      this.resetField();
    })
  }


  get isInvalid() {
    const required = this.parentForm.controls[this.formControlName]?.errors;
    const touched = this.parentForm.controls[this.formControlName]?.touched;
    if (required && touched) {
      return true
    }
    return false
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh(changes);
  }

  init() {
    if (this.type === 'select') {
      // SELECT NORMALE
      $(this.select2?.nativeElement).select2({
        placeholder: this.placeholder,
        minimumResultsForSearch: Infinity,
        allowClear: this.allowClear,
        language: {
          noResults: () => {
            return this.translate.instant('shared.select2.nessun-risultato')
          }
        }
      });
    }
    if (this.type === 'select-search') {
      // SELECT CON CERCA
      $(this.select2?.nativeElement).select2({
        placeholder: this.placeholder,
        allowClear: true,
        language: {
          noResults: () => {
            return this.translate.instant('shared.select2.nessun-risultato')
          }
        }
      });
    }

    if (this.type === 'multiple-select') {
      // MULTIPLE SELECT
      $(document).ready(() => {
        $(this.select2?.nativeElement).select2({
          multiple: true,
          placeholder: this.placeholder,
          closeOnSelect: false,
          language: {
            maximumSelected: () => {
              return this.translate.instant('shared.select2.altre-voci')
            }
          }
        });
        if (this.defaultValue) {
          this.defaultValue.forEach((x: any) => {
            this.multipleSelectedValue?.push({id: x.toString()})
          })
          $(this.select2?.nativeElement).val(this.defaultValue);
          $(this.select2?.nativeElement).trigger('change');
          console.log($(this.select2?.nativeElement).select2("data"))
        }
      })
    }
    $(this.select2?.nativeElement).prop("disabled", this.disabled);

    $(this.select2?.nativeElement).on('select2:select', (e: any) => {
      e.stopImmediatePropagation();
      if (this.type === 'multiple-select') {
        this.onSelectMultiple(e)
      } else {
        this.onSelect(e.params.data.id);
      }
    });

    $(this.select2?.nativeElement).on('select2:unselect', (e: any) => {
      e.stopImmediatePropagation();
      if (this.type === 'multiple-select') {
        this.onDeselectMultiple(e);
      }
    });

    $(this.select2?.nativeElement).on('select2:clear', (e: any) => {
      e.stopImmediatePropagation();
      if (this.type === 'multiple-select') {
        this.multipleSelectedValue = []
      }
      this.clearEvent();
    });
  }

  onSelect(e: any) {
    const value = e;
    const object = {
      [this.formControlName]: value
    };
    this.parentForm.patchValue(object);
    this.cdRef.markForCheck();
  }

  onSelectMultiple(e: any) {
    console.log(Number(e.params.data.id))
    this.multipleSelectedValue?.push({id: e.params.data.id})
    $(this.select2?.nativeElement).select2("data", this.multipleSelectedValue)
    console.log(this.multipleSelectedValue.map(res => res.id))
    console.log($(this.select2?.nativeElement).select2("data"))
    const value = this.multipleSelectedValue.map(res => Number(res.id));
    const object = {
      [this.formControlName]: value
    };
    this.parentForm.patchValue(object)
    this.cdRef.markForCheck()
  }

  onDeselectMultiple(e: any) {
    console.log(this.multipleSelectedValue)
    console.log({id: e.params.data.id})
    for (let i = 0; i < this.multipleSelectedValue.length; i++) {
      if (this.multipleSelectedValue[i].id.toString() === e.params.data.id) {
        this.multipleSelectedValue.splice(i, 1);
      }
    }
    $(this.select2?.nativeElement).select2("data", this.multipleSelectedValue)
    const value = this.multipleSelectedValue.map(res => res.id);
    const object = {
      [this.formControlName]: value
    };
    this.parentForm.patchValue(object)
    this.cdRef.markForCheck();
  }

  clearEvent() {
    this.parentForm.get(this.formControlName)?.reset();
  }

  resetField() {
    $(this.select2?.nativeElement).val(null).trigger('change');
  }

  refresh(changes: SimpleChanges) {
    $(this.select2?.nativeElement).prop("disabled", changes?.disabled?.currentValue);
    if (changes?.disabled) {
      this.resetField();
    }
    if (changes.defaultValue) {
      $(this.select2?.nativeElement).val(changes.defaultValue.currentValue).trigger('change');
      this.cdRef.markForCheck();
    }
    if (changes.options?.currentValue !== changes.options?.previousValue && !changes.options?.firstChange) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    $(this.select2?.nativeElement).select2('destroy');
  }
}
